import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import { Helmet } from 'react-helmet'
import Hero from "../components/hero"

const bannerContent = {
  title: "Sitemap",
  content:
    "We made a very big website with an awful lot of pages, and honestly this page is mostly here so we can remember where they all are, and help Google to find them. But if you're reading this copy, you must be a human that loves sitemaps. Welcome. You are among friends here.",
}

const SiteMap = () => (
  <Layout>
    <SEO
      title="Sitemap | Billsby | Powerful, customizable subscription billing software"
      description="Our sitemap lists all of the pages of the Billsby website."
      url="https://www.billsby.com/sitemap"
    />

    <div className="sitemap">
      <Hero contentHero={bannerContent} />

      <div className="section-sitemap">
        <div className="container container-flex">
          <div className="sitemap-holder">
            <h2 className="section-title">Product</h2>
            <Link to="/product/payment-gateways">Payment gateways</Link>
            <Link to="/product/developers">Billsby for Developers</Link>
            <Link to="/product/support">Obsessive support</Link>
            <Link to="/features">Explore all features</Link>

            <h3 className="section-subtitle">Create your product</h3>
            <Link to="/product/creating/products-plans-cycles">
              Products, plans and cycles
            </Link>
            <Link to="/product/creating/trials-setup-contracts">
              Advanced billing options
            </Link>
            <Link to="/product/creating/addons-allowances">
              Add-ons and allowances
            </Link>
            <Link to="/product/creating/coupon-discounts">
              Coupon codes and discounts
            </Link>
            <Link to="/product/creating/brand-identity">
              Customize your brand identity
            </Link>

            <h3 className="section-subtitle">Get and keep subscribers</h3>
            <Link to="/product/subscribers/checkout-and-payments">
              Checkout and payments
            </Link>
            <Link to="/product/subscribers/sales-tax-and-compliance">
              Sales tax and compliance
            </Link>
            <Link to="/product/subscribers/invoices-creditnotes-emails">
              Invoices and emails
            </Link>
            <Link to="/product/subscribers/account-management">
              In-life account management
            </Link>
            <Link to="/product/subscribers/dunning-retention">
              Dunning and retention
            </Link>

            <h3 className="section-subtitle">Understand your business</h3>
            {/* <Link to="/">Billsby Briefing</Link> */}
            {/* <Link to="/product/reporting/billsby-value-score">
              Billsby Value Score
            </Link> */}
            <Link to="/product/reporting/dashboard">Dashboard</Link>
            <Link to="/product/reporting/reports-and-insight">
              Reports and insights
            </Link>

            <h3 className="section-subtitle">Works with your other tools</h3>
            <Link to="/product/integrations/accounting">Accounting</Link>
            <Link to="/product/integrations/api-and-web-hooks">
              API and web hooks
            </Link>
            {/* <Link to="/product/integrations/customer-service">
              Customer service
            </Link> */}
            <Link to="/product/integrations/feature-tags">Feature tags</Link>
            {/* <Link to="/product/integrations/integromat">Integromat</Link> */}
            {/* <Link to="/product/integrations/planactions">Plan actions</Link> */}
            <Link to="/product/integrations/wordpress">WordPress</Link>
            <Link to="/product/integrations/zapier">Zapier</Link>

            {/* <h3 className="section-subtitle">Billsby Pro</h3>
            <Link to="/pro/advanced-gateways">Advanced gateway selection</Link>
            <Link to="/pro/advanced-value-score">Advanced Value Score</Link>
            <Link to="/pro/affiliate-scheme">Affiliate schemes</Link>
            <Link to="/pro/cardless-trials">Cardless trail</Link>
            <Link to="/pro/debt-collection">Debt Collection</Link>
            <Link to="/pro/development">Development services</Link>
            <Link to="/pro/membership-cards">Membership cards</Link>
            <Link to="/pro/priority-support">Priority Support</Link>
            <Link to="/pro/quotes-bespoke-plans">Quotes and bespoke plans</Link>
            <Link to="/pro/referral-program">Referral programs</Link>
            <Link to="/pro/revenue-recognition">Revenue recognition</Link>
            <Link to="/pro/whitelabel">White label</Link> */}
          </div>
          <div className="sitemap-holder">
            <h2 className="section-title">Solutions</h2>
            <Link to="/solutions/fitness-and-wellbeing">
              Fitness and wellbeing
            </Link>
            <Link to="/solutions/governments">
              Governments and municipalities
            </Link>
            <Link to="/solutions/healthcare-and-education">
              Healthcare and education
            </Link>
            <Link to="/solutions/internet-of-things">Internet of things</Link>
            <Link to="/solutions/media-and-entertainment">
              Media and entertainment
            </Link>
            <Link to="/solutions/memberships-and-clubs">
              Membership and clubs
            </Link>
            <Link to="/solutions/property-and-utilities">
              Property and utilities
            </Link>
            <Link to="/solutions/software-as-a-service">
              Software as a service
            </Link>
            <Link to="/solutions/travel">Travel and leisure</Link>

            <h2 className="section-title">Billsby compared</h2>
            <Link to="/compared">Billsby Compared</Link>
            <Link to="/compare/compare-billwerk">Billwerk</Link>
            <Link to="/compare/compare-chargebee">Chargebee</Link>
            <Link to="/compare/compare-chargify">Chargify</Link>
            <Link to="/compare/compare-fusebill">Fusebill</Link>
            <Link to="/compare/compare-moonclerk">MoonClerk</Link>
            <Link to="/compare/compare-rebilly">Rebilly</Link>
            <Link to="/compare/compare-recurly">Recurly</Link>
            <Link to="/compare/compare-stripe-billing">Stripe</Link>
            <Link to="/compare/compare-subbly">Subbly</Link>
            <Link to="/compare/compare-zuora">Zuora</Link>

            <h2 className="section-title">Information</h2>
            <Link to="/pricing">Pricing</Link>
            <Link to="/security">Security</Link>
            <Link to="/terms">Terms and conditions</Link>
            <Link to="/privacy">Privacy policy</Link>
            <Link to="/terms/acceptable-use">Acceptable use policy</Link>
            <Link to="/privacy/sub-processors">Sub-processor</Link>
            <Link to="/privacy/cookie-policy">Cookie policy</Link>
            <Link to="/privacy/dpa">EU DPA addendum</Link>
          </div>
          <div className="sitemap-holder">
            <h2 className="section-title">Company</h2>
            <Link to="/company/about">About us</Link>
            <Link to="/company/careers">Careers</Link>
            <Link to="/company/press">Press</Link>

            <h2 className="section-title">Resources</h2>
            <h3 className="section-subtitle">Calculators</h3>
            <Link to="/resources/tools/chargeback-calculator">
              Chargeback calculator
            </Link>
            <Link to="/resources/tools/customer-growth-calculator">
              Customer growth calculator
            </Link>
            <Link to="/resources/tools/monthly-recurring-revenue-calculator">
              MMR calculator
            </Link>

            <h3 className="section-subtitle">eBooks</h3>
            <Link to="/resources/guides/combat-chargebacks-in-your-subscription-business">
              Combat chargebacks
            </Link>
            <Link to="/resources/guides/20-ways-to-reduce-churn-and-retain-more-customers">
              Reduce churn
            </Link>
            <Link to="/resources/guides/">Subscription economy in 2020</Link>
            <Link to="/resources/guides/delivering-great-customer-service-to-subscription-customers">
              Deliver great customer service
            </Link>

            <h3 className="section-subtitle">Tools</h3>
            <Link to="/resources/tools/buttongenerator">Button generator</Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default SiteMap
